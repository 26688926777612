import { withContext } from '@/common/utils/withContext';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getChargingStationConsumptionDetails,
  getStatisticsExportDetails,
  getTransactionYear,
  getUserConsumptionDetails,
} from '@/services/statistics';
import { IStatisticsStateModal } from '../../shared/models/statisticsModel';
import { EvesButton } from '@/common/components/atoms/button/button';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  BarElement,
  CategoryScale,
} from 'chart.js';
import {
  buildStackedChartDataForMonths,
  calculateTotalChartDataFromStackedChartData,
  calculateTotalValueFromChartData,
  getGraphLabelVisibility,
  handleHttpError,
  isActive,
  isAdmin,
  isEmptyArray,
  isSuperAdmin,
  updateChartData,
} from '@/common/utils/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Grid from '@mui/material/Grid';
import EvesSelect from '@/common/components/atoms/select/select';
import LookupComponent from '@/common/components/lookupComponent';
import {
  ChartTypeValues,
  LookupEntityType,
  TenantComponents,
} from '@/common/enums/enums';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { Card, InputAdornment, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import DatetimeRangePicker from '@/common/components/atoms/datetimeRangePicker/datetimeRangePicker';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { getSettingsList } from '@/services/integrationSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';

ChartJS.register(
  ArcElement,
  Legend,
  ChartDataLabels,
  BarElement,
  CategoryScale
);

const Consumption = (props: any) => {
  const [showTotalData, setShowTotalData] = useState(false);
  const [showMonthData, setShowMonthData] = useState(true);
  const [apiCallKey, setApiCallKey] = useState(0);
  const chartType = 'consumption';
  const currentYear = new Date().getFullYear();
  const startDate = dayjs(new Date(currentYear, 0, 1, 0, 0, 0));
  const endDate = dayjs(new Date(currentYear + 1, 0, 1, 0, 0, 0));
  const subStationIdLookupRef: any = useRef(null);
  const transformerIdLookupRef: any = useRef(null);
  const feederIdLookupRef: any = useRef(null);
  const chargingStationLookupRef: any = useRef(null);
  const UserLookupRef: any = useRef(null);
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const isUserAdmin = isAdmin();
  const [openAnalyticsList, setOpenAnalyticsList] = useState(null);
  const { t: translate } = useTranslation();
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const [state, setStateData] = useState({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    selectedYear: new Date().getFullYear(),
    consumptionData: [],
    pieChartData: [],
    barChartData: [],
    totalConsumption: 0,
    pieChartBackgroundColor: [],
    chartBackgroundColor: [],
    isResetBtnDisabled: false,
    selectedCategory: 'C',
    transactionYears: [],
    showUserLookUpModal: false,
    isChargingStationLookupModalOpen: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    subStationID: '',
    subStationName: '',
    feederID: '',
    feederName: '',
    transformerID: '',
    transformerName: '',
    userName: '',
    chargingStationName: '',
    siteAreaName: '',
    siteName: '',
    siteID: '',
    siteAreaID: '',
    userID: '',
    chargingStationId: '',
    analyticsOptions: [],
    sacLinksActive: false,
    issuer: null,
  } as IStatisticsStateModal);
  const isOrganizationActive = isActive(TenantComponents.ORGANIZATION);
  const isAdminUser = isAdmin() || isSuperAdmin();
  const yearLabels = [state.transactionYears[0], `${translate('general.all')}`];
  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;
  const ConsumptionExportParams: any = {
    StartDateTime: state.startDate,
    EndDateTime: state.endDate,
    Year: state.selectedYear,
    ChargingStationID: state.chargingStationId,
    DataType: 'Consumption',
    DataCategory: state.selectedCategory,
    DataScope: showTotalData ? 'total' : 'month',
  };
  const userInfo: any = localStorage.getItem('user_info');
  const userLanguage = JSON.parse(userInfo);
  const totalLabel = `${translate('statistics.total')}`;
  const CategoryOptions = [
    { label: `${translate('transactions.charging_station')}`, value: 'C' },
    { label: `${translate('transactions.user')}`, value: 'U' },
  ];
  const isAnalyticsComponentActive = isActive(TenantComponents.ANALYTICS);

  const { refetch: fetchCosumptionYear } = useQuery(
    ['CosumptionYear'],
    () => {
      return getTransactionYear();
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false, // disable this query from automatically running
      onSuccess: async (data: any) => {
        const selectedYear = new Date().getFullYear();
        let transactionYears: any = data;
        if (!transactionYears) {
          transactionYears = [];
        }
        // To be safe always add the current year:
        if (transactionYears?.indexOf(selectedYear) < 0) {
          transactionYears.push(selectedYear);
        }
        transactionYears.push(0); // 'all years' corresponds to year = 0
        setStateData((currentData) => {
          return {
            ...currentData,
            selectedYear: selectedYear,
            transactionYears: transactionYears,
          };
        });
      },
    }
  );

  const { refetch: fetchExportDetails } = useQuery(
    ['CosumptionExport'],
    () => {
      return getStatisticsExportDetails(ConsumptionExportParams);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (result: any) => {
        FileSaver.saveAs(result, `exported-${chartType}-statistics.csv`);
      },
      onError: (error: any) => {
        handleHttpError(error, `${translate('general.error_backend')}`);
      },
    }
  );

  const getConsumptionParams = (): any => {
    const ConsumptionGetParams: any = {
      StartDateTime: state.startDate,
      EndDateTime: state.endDate,
      Year: state.selectedYear,
    };
    if (state.chargingStationId !== '') {
      ConsumptionGetParams['ChargingStationID'] = state.chargingStationId;
    }
    if (state.siteID !== '') {
      ConsumptionGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      ConsumptionGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      ConsumptionGetParams['UserID'] = state.userID;
    }
    if (state.subStationID !== '') {
      ConsumptionGetParams['SubStationID'] = state.subStationID;
    }
    if (state.feederID !== '') {
      ConsumptionGetParams['FeederID'] = state.feederID;
    }
    if (state.transformerID !== '') {
      ConsumptionGetParams['TransformerID'] = state.transformerID;
    }
    return ConsumptionGetParams;
  };

  const { refetch: fetchCosumption } = useQuery(
    ['CosumptionData'],
    () => {
      if (state.selectedCategory == 'C')
        return getChargingStationConsumptionDetails(getConsumptionParams());
      else return getUserConsumptionDetails(getConsumptionParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false, // disable this query from automatically running
      onSuccess: async (response: any) => {
        const barChartData = buildStackedChartDataForMonths(
          response,
          2,
          totalLabel
        );
        const pieChartData =
          calculateTotalChartDataFromStackedChartData(barChartData);
        const totalConsumption = calculateTotalValueFromChartData(barChartData);
        let getColor = [];
        if (showMonthData) {
          getColor = updateChartData(barChartData, ChartTypeValues.BAR);
        } else {
          getColor = updateChartData(pieChartData, ChartTypeValues.PIE);
        }
        setApiCallKey((prevKey) => prevKey + 1);
        setStateData((currentData) => {
          return {
            ...currentData,
            selectedYear: state.selectedYear,
            consumptionData: response,
            barChartData: barChartData,
            pieChartData: pieChartData,
            totalConsumption: totalConsumption,
            chartBackgroundColor: getColor,
          };
        });
      },
    }
  );

  const onDateRangeChange = (e: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        startDate: e[0].toISOString(),
        endDate: e[1].toISOString(),
        isResetBtnDisabled: false,
        selectedYear: -1,
      };
    });
  };

  const { refetch: fetchAnalyticsList } = useQuery(
    '',
    () => {
      return loadAnalyticList();
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        const links = data?.content?.links;
        let sacLinks: any = [];
        if (isUserAdmin) {
          sacLinks = data?.content?.links;
        } else {
          sacLinks = [];
          for (const sacLink of links) {
            if (sacLink.role === 'D') {
              sacLinks.push(sacLink);
            }
          }
        }
        if (!isEmptyArray(sacLinks)) {
          setStateData((currentData) => {
            return {
              ...currentData,
              sacLinksActive: true,
            };
          });
        } else {
          setStateData((currentData) => {
            return {
              ...currentData,
              sacLinksActive: false,
            };
          });
        }

        setStateData((currentData) => {
          return {
            ...currentData,
            analyticsOptions: sacLinks,
          };
        });
      },
    }
  );

  const loadAnalyticList = () => {
    const params: any = {
      Identifier: TenantComponents.ANALYTICS,
    };
    return getSettingsList(params);
  };
  const chartResponsive = {
    height: '80vh',
    width: '100%',
    background: '#fafafa',
  };

  useEffect(() => {
    fetchCosumption();
  }, [
    state.selectedCategory,
    state.selectedYear,
    state.userID,
    state.chargingStationId,
    state.siteAreaID,
    state.siteID,
    state.subStationID,
    state.feederID,
    state.transformerID,
    state.siteName,
    state.siteAreaName,
    state.chargingStationName,
    state.userName,
    state.startDate,
    state.endDate,
  ]);

  useEffect(() => {
    fetchCosumptionYear();
    fetchAnalyticsList();
  }, []);

  const pieData = {
    labels: state.pieChartData.labels,
    datasets: [
      {
        animation: {},
        data: state.pieChartData?.datasets?.[0]?.data ?? [],
        backgroundColor: state.chartBackgroundColor,
      },
    ],
  };
  pieData.datasets[0].animation = {
    animateRotate: true,
    duration: 2000,
    easing: 'easeOutBounce',
  };

  const barChartData = {
    labels: state.barChartData?.labels,
    datasets: state.barChartData?.datasets ?? [],
    backgroundColor: state.chartBackgroundColor,
  };

  const onTotalDataClick = () => {
    setShowTotalData(true);
    setShowMonthData(false);
    const getColor = updateChartData(state.pieChartData, ChartTypeValues.PIE);
    setStateData((currentData) => {
      return {
        ...currentData,
        chartBackgroundColor: getColor,
      };
    });
  };

  const onMonthDataClick = () => {
    setShowMonthData(true);
    setShowTotalData(false);
    const getColor = updateChartData(state.barChartData, ChartTypeValues.BAR);
    setStateData((currentData) => {
      return { ...currentData, chartBackgroundColor: getColor };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isChargingStationLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const setSubStationIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isSubStationIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const setTransformerIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isTransformerIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const setFeederIdFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isFeederIdLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isChargingStationLookupModalOpen: false };
    });
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isSiteLookupModalOpen: true };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSiteLookupModalOpen: false };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedUser = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        userID: '',
        userName: '',
        isResetBtnDisabled:
          !state.userID && !state.chargingStationId && !state.siteAreaID,
      };
    });
  };

  const pieChartOptions = {
    maintainAspectRatio: false,
    color: '#202020',
    elements: { arc: { borderWidth: 0 } },
    plugins: {
      stretch: 2,
      datalabels: {
        display: (context) => {
          return (
            context.dataset.data[context.dataIndex] >
            getGraphLabelVisibility(pieData, false, ChartTypeValues.PIE)
          );
        },
        formatter: (value) =>
          Math.round(value).toLocaleString(userLanguage.language),
        color: '#202020',
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            return (
              context.label +
              ' : ' +
              Math.round(context.parsed.toFixed(4)).toLocaleString(userLanguage.language) +
              ' ' +
              `${translate('statistics.kW.h')}`
            );
          },
        },
      },
    },
  };

  const barChartOptions = {
    maintainAspectRatio: false,
    color: '#202020',
    scales: {
      y: {
        stacked: true,
        grid: {
          display: true,
          color: '#e1e1e1',
        },
        title: {
          display: true,
          text: `${translate('statistics.graphic_title_consumption_y_axis')}`,
          color: '#202020',
        },
        ticks: {
          color: '#202020',
          callback: (value) => value.toLocaleString(userLanguage.language),
        },
      },
      x: {
        grid: {
          display: true,
          color: '#e1e1e1',
        },
        title: {
          display: true,
          text: `${translate('statistics.graphic_title_month_x_axis')}`,
          color: '#202020',
        },
        ticks: { color: '#202020' },
      },
    },

    elements: { arc: { borderWidth: 0 } },
    plugins: {
      stretch: 2,
      datalabels: {
        display: (context) => {
          return (
            context.dataset.data[context.dataIndex] >
            getGraphLabelVisibility(barChartData, true, ChartTypeValues.BAR)
          );
        },
        formatter: (value) =>
          Math.round(value).toLocaleString(userLanguage.language),
        color: '#202020',
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context.label;
          },
          label: function (context) {
            return (
              context.dataset?.label +
              ' : ' +
              Math.round(context.parsed.y).toLocaleString(
                userLanguage.language
              ) +
              ' kW.h'
            );
          },
        },
      },
    },
  };

  const onCategoryChange = (event: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedCategory: event.target.value,
      };
    });
  };

  const onYearChange = (event: any) => {
    if (event.target.value == 0) {
      const startDate = new Date(new Date().getFullYear(), 0, 1);
      const endDate = new Date();
      endDate.setSeconds(0);
      endDate.setHours(23);
      endDate.setMinutes(59);
      setStateData((currentData) => {
        return {
          ...currentData,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          selectedYear: event.target.value,
        };
      });
    } else {
      const currentYear = new Date().getFullYear();
      const startDate = dayjs(new Date(currentYear, 0, 1, 0, 0, 0));
      const endDate = dayjs(new Date(currentYear + 1, 0, 1, 0, 0, 0));
      setStateData((currentData) => {
        return {
          ...currentData,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          selectedYear: event.target.value,
        };
      });
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClickResetFilters = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled: false,
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        chargingStationName: '',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteAreaName: '',
        siteName: '',
        userName: '',
        selectedYear: state.transactionYears[0],
        selectedCategory: 'C',
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return { ...currentData, showUserLookUpModal: false };
    });
  };

  const setUserFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, showUserLookUpModal: true };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return { ...currentData, isSiteAreaLookupModalOpen: true };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSiteAreaLookupModalOpen: false };
    });
  };

  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };

  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };

  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders?.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onTransformerIdSelect = (transformer: any) => {
    const feederName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedSubStation = () => {
    isOnClickSiteFilterClear = true;
    subStationIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        subStationID: '',
        subStationName: '',
        isSubStationLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedFeederId = () => {
    isOnClickSiteFilterClear = true;
    feederIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        feederID: '',
        feederName: '',
        isFeederIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onClearSelectedTransformer = () => {
    isOnClickSiteFilterClear = true;
    transformerIdLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        transformerID: '',
        transformerName: '',
        isTransformerIdLookupModalOpen: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onExportClick = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate('statistics.dialog.consumption.export.title')}`,
        description: `${translate(
          'statistics.dialog.consumption.export.confirm'
        )}`,
        confirmType: 'YES_NO',
      },
      (result) => {
        if (result === 'YES') {
          fetchExportDetails();
        }
      }
    );
  };

  const onConnectChargingClick = (event: any) => {
    setOpenAnalyticsList(event.currentTarget);
  };

  const onConnectChargingClose = () => {
    setOpenAnalyticsList(null);
  };

  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };
const FeederFilter= state.subStationID?false:true;
const TransformFilter= state.feederID?false:true;
  return (
    <>
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'
          title={`${translate('chargers.select_id')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: 'Issuer', value: true },
                  { key: 'SiteAreaID', value: state.siteAreaID },
                ]
              : [{ key: 'Issuer', value: true }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_substation_id')}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_feeder_id')}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_transformer_id')}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'TransformerID', value: state.transformerID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn='name'
        />
      )}
      <Card style={{ padding: '15px', boxShadow: 'none' }}>
        <Grid>
          <Grid
            container
            item
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item lg={2.7} xl={1.9} display={'flex'} gap={'8px'}>
              <Grid item>
                {
                  <EvesButton
                    startIcon={<CalendarMonthIcon />}
                    onClick={onMonthDataClick}
                    data-cy='transactionsrefund-sync-button'
                  >
                    Month
                  </EvesButton>
                }
              </Grid>

              <Grid item>
                {!(state.selectedYear == 0 || state.selectedYear == -1) && (
                  <EvesButton
                    startIcon={<DragHandleIcon />}
                    data-cy='transactionsrefund-refund-button'
                    onClick={onTotalDataClick}
                  >
                    Total
                  </EvesButton>
                )}
              </Grid>
            </Grid>
            <Grid item lg={7} xl={6.8}>
              {showfilter && (
                <Grid container item spacing={2} rowSpacing={1} sx={alignStyle}>
                  {isAdminUser && (
                    <Grid item lg={2.5} xl={2.2}>
                      <EvesSelect
                        data-cy='category-option'
                        label={`${translate('statistics.category_label')}`}
                        options={CategoryOptions}
                        onChange={onCategoryChange}
                        value={state.selectedCategory}
                      />
                    </Grid>
                  )}

                  <Grid item lg={2.5} xl={2.2}>
                    <EvesSelect
                      data-cy='year-option'
                      label={`${translate('statistics.transactions_years')}`}
                      options={state.transactionYears?.map((year, index) => ({
                        label: yearLabels[index],
                        value: year,
                      }))}
                      onChange={onYearChange}
                      shrink={state.selectedYear == -1 ? false : true}
                      value={state.selectedYear}
                    />
                  </Grid>
                  <Grid item lg={2.5} xl={2.2} data-cy='cons-dr-picker'>
                    <Stack>
                      <DatetimeRangePicker
                        isDefaultRangeShortcut={false}
                        defaultStartDate={startDate}
                        defaultEndDate={endDate}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        cleanable={false}
                        onDateRangeChange={onDateRangeChange}
                      />
                    </Stack>
                  </Grid>
                  {isOrganizationActive && (
                    <Grid item lg={2.5} xl={2.2}>
                      <EvesTextbox
                        value={state.siteName}
                        id='statistics-site-search'
                        label={`${translate('sites.titles')}`}
                        data-cy='select-site'
                        type='text'
                        variant='standard'
                        onClick={setSiteFilter}
                        onChange={onFilterApplied}
                        ref={siteLookupRef}
                        isFilter={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedSite}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )}
                  {isOrganizationActive && (
                    <Grid item lg={2.5} xl={2.2}>
                      <EvesTextbox
                        value={state.siteAreaName}
                        id='statistics-sitearea-search'
                        label={`${translate('site_areas.titles')}`}
                        data-cy='select-site-area'
                        type='text'
                        variant='standard'
                        onClick={setSiteAreaFilter}
                        onChange={onFilterApplied}
                        isFilter={true}
                        ref={siteAreaLookupRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedSiteArea}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item lg={2.5} xl={2.2}>
                    <EvesTextbox
                      value={state.subStationName}
                      style={{ width: '100%', cursor: 'pointer' }}
                      id='statistics-sub-station-id-lookup'
                      label={`${translate('site_areas.sub_station_id')}`}
                      type='text'
                      variant='standard'
                      data-cy='statistics-sub-station-id-lookup'
                      onClick={setSubStationIdFilter}
                      onChange={onFilterApplied}
                      ref={subStationIdLookupRef}
                      isFilter={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              onClick={onClearSelectedSubStation}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={2.5} xl={2.2}>
                    <EvesTextbox
                      value={state.feederName}
                      style={{ width: '100%', cursor: 'pointer' }}
                      id='statistics-feeder-id-lookup'
                      label={`${translate('site_areas.feeder_id')}`}
                      type='text'
                      variant='standard'
                      data-cy='statistics-feeder-id-lookup'
                      onClick={setFeederIdFilter}
                      onChange={onFilterApplied}
                      ref={feederIdLookupRef}
                      disabled={FeederFilter}
                      isFilter={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              disabled={FeederFilter}
                              onClick={onClearSelectedFeederId}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={2.5} xl={2.2}>
                    <EvesTextbox
                      value={state.transformerName}
                      style={{ width: '100%', cursor: 'pointer' }}
                      id='statistics-transformer-id-lookup'
                      label={`${translate('site_areas.transformer_id')}`}
                      type='text'
                      variant='standard'
                      data-cy='statistics-transformer-id-lookup'
                      onClick={setTransformerIdFilter}
                      onChange={onFilterApplied}
                      disabled={TransformFilter}
                      ref={transformerIdLookupRef}
                      isFilter={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              disabled={TransformFilter}
                              fontSize={15}
                              onClick={onClearSelectedTransformer}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={5.1} xl={2.2}>
                    <EvesTextbox
                      value={state.chargingStationName}
                      style={{ width: '100%', cursor: 'pointer' }}
                      id='statistics-charging-station-lookup'
                      label={`${translate('chargers.titles')}`}
                      type='text'
                      variant='standard'
                      data-cy='statistics-charging-station-lookup'
                      onClick={setChargingStationFilter}
                      onChange={onFilterApplied}
                      ref={chargingStationLookupRef}
                      isFilter={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              onClick={onClearSelectedChargingStation}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={5.1} xl={2.2}>
                    <EvesTextbox
                      value={state.userName}
                      id='User-standard-search'
                      label={`${translate('logs.users')}`}
                      data-cy='User-filter'
                      type='text'
                      variant='standard'
                      onClick={setUserFilter}
                      onChange={onFilterApplied}
                      isFilter={true}
                      ref={UserLookupRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <EvesIconButton
                              icon={Close}
                              fontSize={15}
                              onClick={onClearSelectedUser}
                            ></EvesIconButton>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item gap={'10px'} lg={2.3} xl={2.3}>
              <ButtonGroup
                {...{
                  showfilter,
                  setShowfilter,
                  resetFilters: onClickResetFilters,
                  state,
                  onClickExport: onExportClick,
                  onRefresh: fetchCosumption,
                  isStatistics: true,
                  isExport: true,
                  isAnalyticsComponentActive,
                  onConnectChargingClick,
                  openAnalyticsList,
                  onConnectChargingClose,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12}>
        <div style={chartResponsive}>
          {showTotalData && (
            <Pie data={pieData} options={pieChartOptions} key={apiCallKey} />
          )}
          {showMonthData && (
            <Bar
              data={barChartData}
              options={barChartOptions}
              key={apiCallKey}
            />
          )}
        </div>
      </Grid>
    </>
  );
};

export default withContext(Consumption);
